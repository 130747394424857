import React, { lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
const Home = lazy(() => import('../components/Home'));
const DataTypes = lazy(() => import('./datatypes/DataTypes'));
const NewDataType = lazy(() => import('./datatypes/NewDataType'));
const EditDataType = lazy(() => import('./datatypes/EditDataType'));
const ViewDataType = lazy(() => import('./datatypes/ViewDataType'));
const Templates = lazy(() => import('./templates/Templates'));
const NewTemplate = lazy(() => import('./templates/NewTemplate'));
const EditTemplate = lazy(() => import('./templates/EditTemplate'));
const ViewTemplate = lazy(() => import('./templates/ViewTemplate'));
const ProductGroupMappings = lazy(() => import('./templates/ProductGroupMappings'));
const ItemTabs = lazy(() => import('./templates/ItemTabs'));
const NewItemTab = lazy(() => import('./templates/NewItemTab'));
const EditItemTab = lazy(() => import('./templates/EditItemTab'));
const ViewItemTab = lazy(() => import('./templates/ViewItemTab'));
const B2BTabs = lazy(() => import('./templates/B2BTabs'));
const NewB2BTab = lazy(() => import('./templates/NewB2BTab'));
const EditB2BTab = lazy(() => import('./templates/EditB2BTab'));
const ViewB2BTab = lazy(() => import('./templates/ViewB2BTab'));
const Phases = lazy(() => import('./phases/Phases'));
const NewPhase = lazy(() => import('./phases/NewPhase'));
const EditPhase = lazy(() => import('./phases/EditPhase'));
const Items = lazy(() => import('./items/Items'));
const VendorItems = lazy(() => import('./items/VendorItems'));
const BulkEditor = lazy(() => import('./items/BulkEditor'));
const CSVBulkEditor = lazy(() => import('./items/CSVBulkEditor'));
const EditItem = lazy(() => import('./items/EditItem'));
const ViewItem = lazy(() => import('./items/ViewItem'));
const NewItem = lazy(() => import('./items/NewItem'));
const CloneItem = lazy(() => import('./items/CloneItem'));
const EditItemInfo = lazy(() => import('./items/EditItemInfo'));
const ImportItems = lazy(() => import('./items/ImportItems'));
const CartItems = lazy(() => import('./cart/CartItems'));
const SendEditInvite = lazy(() => import('./cart/SendEditInvite'));
const BulkEditTemplates = lazy(() => import('./cart/BulkEditTemplates'));
const Groups = lazy(() => import('./groups/Groups'));
const NewGroup = lazy(() => import('./groups/NewGroup'));
const EditGroup = lazy(() => import('./groups/EditGroup'));
const ItemWarnings = lazy(() => import('./items/Warnings'));
const PurchaseThemes = lazy(() => import('./purchase/PurchaseThemes'));
const EditPurchaseTheme = lazy(() => import('./purchase/EditPurchaseTheme'));
const NewPurchaseTheme = lazy(() => import('./purchase/NewPurchaseTheme'));
const ViewTheme = lazy(() => import('./purchase/ViewTheme'));
const ViewQuote = lazy(() => import('./purchase/ViewQuote'));
const NewQuote = lazy(() => import('./purchase/NewQuote'));
const EditQuote = lazy(() => import('./purchase/EditQuote'));
const EditQuoteAmounts = lazy(() => import('./purchase/EditQuoteAmounts'));
const Quotes = lazy(() => import('./purchase/Quotes'));
const ImportQuoteItems = lazy(() => import('./purchase/ImportQuoteItems'));
const Requirements = lazy(() => import('./purchase/Requirements'));
const NewRequirement = lazy(() => import('./purchase/NewRequirement'));
const EditRequirement = lazy(() => import('./purchase/EditRequirement'));
const Containers = lazy(() => import('./purchase/Containers'));
const EditContainer = lazy(() => import('./purchase/EditContainer'));
const NewContainer = lazy(() => import('./purchase/NewContainer'));
const NegotiationDefaults = lazy(() => import('./purchase/NegotiationDefaults'));
const Purchase = lazy(() => import('../components/purchase/Purchase'));
const Collections = lazy(() => import('./collections/Collections'));
const ViewCollection = lazy(() => import('./collections/ViewCollection'));
const EditCollection = lazy(() => import('./collections/EditCollection'));
const NewCollection = lazy(() => import('./collections/NewCollection'));
const RytrTemplates = lazy(() => import('./rytr/RytrTemplates'));
const NewRytrTemplate = lazy(() => import('./rytr/NewRytrTemplate'));
const EditRytrTemplate = lazy(() => import('./rytr/EditRytrTemplate'));
const ViewRytrTemplate = lazy(() => import('./rytr/ViewRytrTemplate'));
const OpenAITemplates = lazy(() => import('./openai/OpenAITemplates'));
const NewOpenAITemplate = lazy(() => import('./openai/NewOpenAITemplate'));
const EditOpenAITemplate = lazy(() => import('./openai/EditOpenAITemplate'));
const ViewOpenAITemplate = lazy(() => import('./openai/ViewOpenAITemplate'));

export default function Routes({ user }) {
	const canViewTemplates = user.roles.includes('PIM:ViewTemplates');
	const canEditTemplates = user.roles.includes('PIM:EditTemplate');
	const canViewPhases = user.roles.includes('PIM:ViewPhases');
	const canEditPhases = user.roles.includes('PIM:EditPhase');
	const canViewItems = user.roles.includes('PIM:ViewItems');
	const canEditItem = user.roles.includes('PIM:EditItem');
	const canViewGroups = user.roles.includes('PIM:ViewGroups');
	const canEditGroups = user.roles.includes('PIM:EditGroup');
	const isVendor = user.roles.includes('PIM:Vendor');
	const canInvite = canViewItems && user.roles.includes('PIM:MailEditItem');
	const canViewThemes = user.roles.includes('PIM:ViewPurchaseThemes');
	const canEditTheme = user.roles.includes('PIM:EditPurchaseTheme');
	const canViewQuotes = user.roles.includes('PIM:ViewQuotes');
	const canEditQuote = user.roles.includes('PIM:EditQuote');
	const canViewCollections = user.roles.includes('PIM:ViewCollections');
	const canEditCollection = user.roles.includes('PIM:EditCollection');
	const canViewVendorRequirements = user.roles.includes('PIM:ViewVendorRequirements');
	const canEditVendorRequirement = user.roles.includes('PIM:EditVendorRequirement');
	const canViewRytrTemplates = user.roles.includes('PIM:ViewRytrTemplates');
	const canEditRytrTemplate = user.roles.includes('PIM:EditRytrTemplate');
	const canViewOpenAITemplates = user.roles.includes('PIM:ViewOpenAITemplates');
	const canEditOpenAITemplate = user.roles.includes('PIM:EditOpenAITemplate');
	return <Switch>
		{canEditTheme && <Route path="/purchase/themes/edit/:id" children={<EditPurchaseTheme />} />}
		{canEditTheme && <Route path="/purchase/themes/new">
			<NewPurchaseTheme />
		</Route>}
		{canViewThemes && <Route path="/purchase/themes/view/:id"><ViewTheme user={user} /></Route>}
		{canViewThemes && <Route path="/purchase/themes">
			<PurchaseThemes user={user} />
		</Route>}
		{canEditQuote && <Route path="/purchase/quotes/items/:id"><ImportQuoteItems /></Route>}
		{canViewQuotes && <Route path="/purchase/quotes/view/:id"><ViewQuote /></Route>}
		{canEditQuote && <Route path="/purchase/quotes/new/:id"><NewQuote /></Route>}
		{canEditQuote && <Route path="/purchase/quotes/amounts/:id"><EditQuoteAmounts /></Route>}
		{canEditQuote && <Route path="/purchase/quotes/edit/:id/lines"><EditQuote final /></Route>}
		{(canEditQuote || isVendor) && <Route path="/purchase/quotes/edit/:id"><EditQuote vendor={!canEditQuote} /></Route>}
		{canViewQuotes && <Route path="/purchase/quotes">
			<Quotes user={user} />
		</Route>}
		{canEditVendorRequirement && <Route path="/purchase/vendorrequirements/edit/:id"><EditRequirement /></Route>}
		{canEditVendorRequirement && <Route path="/purchase/vendorrequirements/new"><NewRequirement /></Route>}
		{canViewVendorRequirements && <Route path="/purchase/vendorrequirements">
			<Requirements user={user} />
		</Route>}
		{canEditQuote && <Route path="/purchase/containers/new" children={<NewContainer />} />}
		{canEditQuote && <Route path="/purchase/containers/edit/:id" children={<EditContainer />} />}
		{canEditQuote && <Route path="/purchase/containers" children={<Containers />} />}
		{canEditVendorRequirement && <Route path="/purchase/negotiationsettings" children={<NegotiationDefaults />} />}
		{(canViewVendorRequirements || canViewThemes || canViewQuotes) && <Route path="/purchase" children={<Purchase user={user} />} />}
		{canEditTemplates && <Route path="/datatypes/new">
			<NewDataType />
		</Route>}
		{canEditTemplates && <Route path="/datatypes/edit/:id" children={<EditDataType />} />}
		{canViewTemplates && <Route path="/datatypes/view/:id" children={<ViewDataType />} />}
		{canViewTemplates && <Route path="/datatypes">
			<DataTypes user={user} />
		</Route>}
		{canViewTemplates && <Route path="/templates/tabs/view/:id">
			<ViewItemTab />
		</Route>}
		{canEditTemplates && <Route path="/templates/tabs/edit/:id">
			<EditItemTab />
		</Route>}
		{canEditTemplates && <Route path="/templates/tabs/new">
			<NewItemTab />
		</Route>}
		{canViewTemplates && <Route path="/templates/tabs">
			<ItemTabs user={user} />
		</Route>}
		{canViewTemplates && <Route path="/templates/b2btabs/view/:id">
			<ViewB2BTab />
		</Route>}
		{canEditTemplates && <Route path="/templates/b2btabs/edit/:id">
			<EditB2BTab />
		</Route>}
		{canEditTemplates && <Route path="/templates/b2btabs/new">
			<NewB2BTab />
		</Route>}
		{canViewTemplates && <Route path="/templates/b2btabs">
			<B2BTabs user={user} />
		</Route>}
		{canViewTemplates && <Route path="/templates/productgroupmappings">
			<ProductGroupMappings user={user} />
		</Route>}
		{canEditRytrTemplate && <Route path="/templates/rytr/view/:id"><ViewRytrTemplate user={user} /></Route>}
		{canEditRytrTemplate && <Route path="/templates/rytr/edit/:id"><EditRytrTemplate /></Route>}
		{canEditRytrTemplate && <Route path="/templates/rytr/new"><NewRytrTemplate /></Route>}
		{canViewRytrTemplates && <Route path="/templates/rytr/disabled"><RytrTemplates user={user} disabled /></Route>}
		{canViewRytrTemplates && <Route path="/templates/rytr"><RytrTemplates user={user} /></Route>}
		{canEditOpenAITemplate && <Route path="/templates/openai/view/:id"><ViewOpenAITemplate user={user} /></Route>}
		{canEditOpenAITemplate && <Route path="/templates/openai/edit/:id"><EditOpenAITemplate /></Route>}
		{canEditOpenAITemplate && <Route path="/templates/openai/new"><NewOpenAITemplate /></Route>}
		{canViewOpenAITemplates && <Route path="/templates/openai/disabled"><OpenAITemplates user={user} disabled /></Route>}
		{canViewOpenAITemplates && <Route path="/templates/openai"><OpenAITemplates user={user} /></Route>}
		{canEditTemplates && <Route path="/templates/new">
			<NewTemplate />
		</Route>}
		{canEditTemplates && <Route path="/templates/edit/:id" children={<EditTemplate />} />}
		{canViewTemplates && <Route path="/templates/view/:id" children={<ViewTemplate />} />}
		{canViewTemplates && <Route path="/templates/disabled">
			<Templates user={user} disabled />
		</Route>}
		{canViewTemplates && <Route path="/templates/unpublished">
			<Templates user={user} published={false} />
		</Route>}
		{canViewTemplates && <Route path="/templates">
			<Templates user={user} published />
		</Route>}
		{canEditPhases && <Route path="/phases/edit/:id" children={<EditPhase />} />}
		{canEditPhases && <Route path="/phases/new">
			<NewPhase />
		</Route>}
		{canViewPhases && <Route path="/phases">
			<Phases user={user} />
		</Route>}

		{canEditItem && <Route path="/items/bulk/import" children={<CSVBulkEditor />} />}
		{canEditItem && <Route path="/items/bulk" children={<BulkEditor />} />}
		{canEditItem && <Route path="/items/form/all/:id" children={<EditItem editAll />} />}
		{canEditItem && <Route path="/items/form/current/:id" children={<EditItem editAll="current" />} />}
		{canEditItem && <Route path="/items/form/previous/:id" children={<EditItem editAll="previous" />} />}
		{(canEditItem || isVendor) && <Route path="/items/form/:id" children={<EditItem single={!canEditItem} />} />}
		{canEditItem && <Route path="/items/edit/:id" children={<EditItemInfo />} />}
		{canEditItem && <Route path="/items/clone/:id" children={<CloneItem />} />}
		{canViewItems && <Route path="/items/view/all/:id" children={<ViewItem editAll />} />}
		{canViewItems && <Route path="/items/view/:id" children={<ViewItem />} />}
		{canEditItem && <Route path="/items/form">
			<NewItem user={user} />
		</Route>}
		{canEditItem && <Route path="/items/import">
			<ImportItems user={user} />
		</Route>}
		{canViewItems && <Route path="/items">
			<Items user={user} />
		</Route>}
		{isVendor && <Route path="/vendor/items" children={<VendorItems user={user} />} />}
		{canInvite && <Route path="/cart/mailinvite">
			<SendEditInvite user={user} />
		</Route>}
		{canEditItem && <Route path="/cart/edittemplates">
			<BulkEditTemplates user={user} />
		</Route>}
		{canViewItems && <Route path="/cart">
			<CartItems user={user} />
		</Route>}

		{canEditGroups && <Route path="/groups/edit/:id" children={<EditGroup />} />}
		{canEditGroups && <Route path="/groups/new">
			<NewGroup />
		</Route>}
		{canViewGroups && <Route path="/groups">
			<Groups user={user} />
		</Route>}

		{canViewItems && <Route path="/warnings">
			<ItemWarnings user={user} />
		</Route>}

		{canViewCollections && <Route path="/collections/view/:id"><ViewCollection user={user} /></Route>}
		{canEditCollection && <Route path="/collections/edit/:id"><EditCollection user={user} /></Route>}
		{canEditCollection && <Route path="/collections/new"><NewCollection /></Route>}
		{canViewCollections && <Route path="/collections"><Collections user={user} /></Route>}

		<Route>
			<Home user={user} />
		</Route>
	</Switch>;
}
