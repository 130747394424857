import axios from 'axios';
import HttpStatus from 'http-status-codes';
import { stringify, parse } from 'query-string';

export const BASE = '/api';

const client = axios.create({
	baseURL: BASE,
	paramsSerializer: {
		encode: parse,
		serialize: stringify
	}
});
const cache = Object.create(null);

function getLoginUrl() {
	return `${window.location.origin}${BASE}/auth/login?redirect=${encodeURIComponent(window.location)}`;
}

export async function getMe() {
	try {
		const { ott } = parse(window.location.search);
		const headers = {};
		if(ott) {
			headers['PTMD-OTT'] = ott;
		}
		const res = await client.get('/auth/me', { headers });
		return res.data;
	} catch(err) {
		if(err.response?.data && err.response.status === HttpStatus.UNAUTHORIZED) {
			const { login } = err.response.data;
			err.loginUrl = `${login}?login=${encodeURIComponent(getLoginUrl())}`;
		}
		throw err;
	}
}

export function logout() {
	return client.post('/auth/logout');
}

function getData(res) {
	return res.data;
}

export async function getAllPages(getter) {
	let page = 1;
	const out = [];
	let stop = false;
	do {
		const res = await getter(page);
		out.push(...res.items);
		stop = res.items.length < res.perPage;
		page++;
	} while(!stop);
	return out;
}

export function getDataType(id) {
	return client.get(`/editor/datatypes/${encodeURIComponent(id)}`).then(getData);
}

export function getDataTypes(page) {
	return client.get('/editor/datatypes', { params: { page } }).then(getData);
}

export function getAllDataTypes() {
	return client.get('/editor/all/datatypes').then(getData);
}

export function saveDataType(dataType) {
	return client.post('/editor/datatypes', dataType).then(getData);
}

export function updateDataType({ _id, ...dataType }) {
	return client.put(`/editor/datatypes/${encodeURIComponent(_id)}`, dataType);
}

export function getDataTypeEnumSchema(id) {
	return client.get(`/editor/datatypes/${encodeURIComponent(id)}/enumschema`).then(getData);
}

export function getDataTypeSchema(id, array = true) {
	return client.get(`/editor/datatypes/${encodeURIComponent(id)}/schema`, { params: { array } }).then(getData);
}

export function getTemplates(disabled, published) {
	const params = {};
	if(disabled) {
		params.disabled = true;
	}
	if(typeof published === 'boolean') {
		params.published = published;
	}
	return client.get('/editor/templates', { params }).then(getData);
}

export function saveTemplate(template) {
	return client.post('/editor/templates', template).then(getData);
}

export function getTemplate(id, resolveFields) {
	const params = {};
	if(resolveFields) {
		params.resolveFields = true;
	}
	return client.get(`/editor/templates/${encodeURIComponent(id)}`, { params }).then(getData);
}

export function findTemplates(query, published) {
	return client.get('/editor/autocomplete/templates', { params: { query, published } }).then(getData);
}

export function getAllTemplates() {
	return client.get('/editor/templates/all').then(getData);
}

export function getBaseTemplates() {
	return client.get('/editor/templates/base').then(getData);
}

export function updateTemplate({ _id, ...template }) {
	return client.put(`/editor/templates/${encodeURIComponent(_id)}`, template);
}

export function getOverlappingFields(field, templates) {
	return client.post('/editor/overlapping/templates', { field, templates }).then(getData);
}

export function getPhases(page) {
	return client.get('/editor/phases', { params: { page } }).then(getData);
}

export function getPhase(id) {
	return client.get(`/editor/phases/${encodeURIComponent(id)}`).then(getData);
}

export function savePhase(phase) {
	return client.post('/editor/phases', phase).then(getData);
}

export function updatePhase({ _id, ...phase }) {
	return client.put(`/editor/phases/${encodeURIComponent(_id)}`, phase);
}

export function getAllPhases(includeSubphases) {
	const params = {};
	if(includeSubphases) {
		params.subphases = true;
	}
	return client.get('/editor/all/phases', { params }).then(getData);
}

export function getItemForm(id, showAll) {
	const params = {};
	if(showAll) {
		params.showAll = showAll;
	}
	return client.get(`/editor/form/items/${encodeURIComponent(id)}`, { params }).then(getData);
}

export function updateItemForm(id, itemForm, editAll) {
	const params = {};
	if(editAll) {
		params.editAll = editAll;
	}
	return client.put(`/editor/form/items/${encodeURIComponent(id)}`, itemForm, { params });
}

export function getBulkItemForm(items) {
	return client.post('/editor/bulk/items', { items }).then(getData);
}

export function updateBulkItemForm(items, updates) {
	return client.put('/editor/bulk/items', { items, updates });
}

export function bulkUpdateTemplates(items, templates) {
	return client.put('/editor/bulk/itemtemplates', { items, templates });
}

export function getItems(page, search) {
	return client.get('/editor/items', { params: { ...search, page } }).then(getData);
}

export function getItemsInSequence(item, direction, query) {
	return client.post('/editor/sequence/items', { item, direction, query }).then(getData);
}

export function getVendorItems(page, search) {
	return client.get('/editor/vendor/items', { params: { page, ...search } }).then(getData);
}

export function getItem(id) {
	return client.get(`/editor/items/${encodeURIComponent(id)}`).then(getData);
}

export function saveItem(item) {
	return client.post('/editor/items', item).then(getData);
}

export function updateItem(id, item) {
	return client.put(`/editor/items/${encodeURIComponent(id)}`, item);
}

export function cloneItem(id, item) {
	return client.post(`/editor/clone/items/${encodeURIComponent(id)}`, item);
}

export function updateItemPhase(id, phase) {
	return updateItem(id, { phase });
}

export function deleteItem(id, item) {
	return client.delete(`/editor/items/${encodeURIComponent(id)}`, item);
}

export function getCartItems(items) {
	return client.post('/items/byId', { items }).then(getData);
}

async function getCached(key) {
	if(cache[key]) {
		return cache[key];
	}
	cache[key] = client.get(key).then(getData);
	try {
		return await cache[key];
	} finally {
		delete cache[key];
	}
}

export function getProductGroups() {
	return getCached('/editor/all/productgroups');
}

export function getLifecycles() {
	return getCached('/editor/all/lifecycles');
}

export function findVendors(query) {
	return client.get('/editor/autocomplete/vendors', { params: { query } }).then(getData);
}

export function getVendor(id) {
	return client.get(`/editor/vendors/${encodeURIComponent(id)}`).then(getData);
}

export function getGroups(page) {
	return client.get('/groups', { params: { page } }).then(getData);
}

export function getAllGroups() {
	return client.get('/groups/all').then(getData);
}

export function getGroup(id) {
	return client.get(`/groups/${encodeURIComponent(id)}`).then(getData);
}

export function saveGroup(group) {
	return client.post('/groups', group);
}

export function updateGroup(id, group) {
	return client.put(`/groups/${encodeURIComponent(id)}`, group);
}

export function findGroups(query) {
	return client.get('/autocomplete/groups', { params: { query } }).then(getData);
}

export function deleteGroup(id) {
	return client.delete(`/groups/${encodeURIComponent(id)}`);
}

export function getGroupCompletions() {
	return client.get('/groups/completion').then(getData);
}

export function getGroupSentInfo() {
	return client.get('/groups/vendor').then(getData);
}

export function getVendorSentInfo() {
	return client.get('/vendor/items/sent').then(getData);
}

export function findItems(query) {
	return client.get('/editor/autocomplete/items', { params: { query } }).then(getData);
}

export function addToGroup(group, items) {
	return client.put(`/items/group/${encodeURIComponent(group)}`, { items });
}

export function sendItemEditInvite(vendor, items, emails) {
	return client.post('/mail/edititems', { vendor, items, emails });
}

export function getVendorsForItems(items, phase) {
	return client.post('/mail/edititems/vendors', { items, phase }).then(getData);
}

export function getTask(id) {
	return client.get(`/tasks/${encodeURIComponent(id)}`);
}

export function syncItems(items) {
	return client.post('/items/navsync', { items }).then(getData);
}

export function importItems(items) {
	return client.post('/items/import', { items }).then(getData);
}

export function bulkUpdateItems(items) {
	return client.post('/items/edit/bulk', items).then(getData);
}

export function generateItemTexts(items) {
	return client.post('/items/aigen', { items }).then(getData);
}

export function getProductGroupMappings() {
	return client.get('/templates/productgroupmappings').then(getData);
}

export function updateProductGroupMappings(mappings) {
	return client.put('/templates/productgroupmappings', mappings);
}

export function getTemplatesForItems(items) {
	return client.post('/templates/combined', { items }).then(getData);
}

export function updateItemPhases(phase, items) {
	return client.put(`/items/phase/${encodeURIComponent(phase)}`, { items });
}

export function getRoles() {
	return client.get('/roles').then(getData);
}

export function getItemWarnings(page, search) {
	return client.get('/warnings/items', { params: { page, ...search } }).then(getData);
}

export function ignoreWarning(item, ignored) {
	return client.put(`/warnings/items/${item}`, { ignored });
}

function createInput(name, type = 'hidden') {
	const input = document.createElement('input');
	input.hidden = true;
	input.type = type;
	input.name = name;
	return input;
}

function callRawMethod(method, url, data) {
	const form = document.createElement('form');
	form.method = method;
	form.action = url;
	document.body.appendChild(form);
	if(data) {
		Object.keys(data).forEach(key => {
			const input = createInput(key);
			input.value = JSON.stringify(data[key], data);
			form.appendChild(input);
		});
	}
	form.submit();
	document.body.removeChild(form);
}

export function exportItems(items) {
	callRawMethod('POST', `${BASE}/items/export`, { items });
}

export function findPurchaseThemes(query) {
	return client.get('/autocomplete/purchase/themes', { params: { query } }).then(getData);
}

export function getActivePurchaseThemes() {
	return client.get('/purchase/all/themes').then(getData);
}

export function getPurchaseThemes(page, { active }) {
	return client.get('/purchase/themes', { params: { page, active } }).then(getData);
}

export function getPurchaseTheme(id, quotes = false) {
	return client.get(`/purchase/themes/${encodeURIComponent(id)}`, { params: { quotes } }).then(getData);
}

export function createPurchaseTheme(theme) {
	return client.post('/purchase/themes', theme);
}

export function updatePurchaseTheme({ _id, ...theme }) {
	return client.put(`/purchase/themes/${encodeURIComponent(_id)}`, theme);
}

export function deletePurchaseTheme(id) {
	return client.delete(`/purchase/themes/${encodeURIComponent(id)}`);
}

export function getQuote(id, params) {
	return client.get(`/purchase/quotes/${encodeURIComponent(id)}`, { params }).then(getData);
}

export function createQuote(quote) {
	return client.post('/purchase/quotes', quote);
}

export function updateQuote(id, quote) {
	return client.put(`/purchase/quotes/${encodeURIComponent(id)}`, quote).then(getData);
}

export function updateQuoteAmounts(id, quote) {
	return client.put(`/purchase/quotes/${encodeURIComponent(id)}/amounts`, quote);
}

export function getQuotes(page, { status, vendor, theme, actor, item }) {
	return client.get('/purchase/quotes', { params: { page, status, vendor, theme, actor, item } }).then(getData);
}

export function inviteQuote(id) {
	return client.post(`/purchase/quotes/${encodeURIComponent(id)}/invite`).then(getData);
}

export function deleteQuote(id) {
	return client.delete(`/purchase/quotes/${encodeURIComponent(id)}`);
}

export function getQuotePreview(id) {
	return client.get(`/purchase/quotes/${encodeURIComponent(id)}/preview`).then(getData);
}

export function getQuoteDocuments(id) {
	return client.get(`/purchase/quotes/${encodeURIComponent(id)}/documents`).then(getData);
}

export function importQuoteItems(id, items) {
	return client.post(`/purchase/quotes/${encodeURIComponent(id)}/items`, { items }).then(getData);
}

export function syncPurchaseOrder(id) {
	return client.post(`/purchase/quotes/${encodeURIComponent(id)}/syncPurchaseOrder`);
}

export function sendSignatureRequest(id) {
	return client.post(`/purchase/quotes/${encodeURIComponent(id)}/signrequest`, { url: window.location.href }).then(getData);
}

export function cloneQuote(id) {
	return client.post(`/purchase/quotes/${encodeURIComponent(id)}/clone`).then(getData);
}

export function repealQuote(id) {
	return client.post(`/purchase/quotes/${encodeURIComponent(id)}/repeal`).then(getData);
}

export function sendEditQuote(id) {
	return client.post(`/mail/quote/${encodeURIComponent(id)}`);
}

export function createVendorToken(vendor) {
	return client.post('/mail/token/vendor', { vendor }).then(getData);
}

export function getCollections(page, { used }) {
	return client.get('/collections', { params: { page, used } }).then(getData);
}

export function getAllCollections() {
	return getCached('/collections/all');
}

export function getCollection(id) {
	return client.get(`/collections/${encodeURIComponent(id)}`).then(getData);
}

export function deleteCollection(id) {
	return client.delete(`/collections/${encodeURIComponent(id)}`).then(getData);
}

export function editCollection({ _id, ...collection }) {
	return client.put(`/collections/${encodeURIComponent(_id)}`, collection);
}

export function createCollection(collection) {
	return client.post('/collections', collection);
}

export function findCollections(query) {
	return client.get('/autocomplete/collections', { params: { query } }).then(getData);
}

export function getRandomCollection(amount=1) {
	return client.get(`/random/collection/${encodeURIComponent(amount)}`).then(getData);
}

export function getVendorRequirements(page) {
	return client.get('/purchase/vendorrequirements', { params: { page } }).then(getData);
}

export function createVendorRequirement({ name, active, file }) {
	const formData = new FormData();
	formData.append('name', name);
	formData.append('active', active);
	formData.append('file', file, file.name);
	return client.post('/purchase/vendorrequirements', formData);
}

export function getVendorRequirement(id) {
	return client.get(`/purchase/vendorrequirements/${encodeURIComponent(id)}`).then(getData);
}

export function updateVendorRequirement({ _id, ...requirement }) {
	return client.put(`/purchase/vendorrequirements/${encodeURIComponent(_id)}`, requirement);
}

export function getAllVendorRequirements() {
	return client.get('/purchase/all/vendorrequirements').then(getData);
}

export function getPorts() {
	return client.get('/purchase/ports').then(getData);
}

export function getDefaultNegotiationSettings() {
	return client.get('/purchase/negotiationsettings').then(getData);
}

export function setDefaultNegotiationSettings(settings) {
	return client.put('/purchase/negotiationsettings', settings);
}

export function getContainers() {
	return client.get('/containers').then(getData);
}

export function getContainer(id) {
	return client.get(`/containers/${encodeURIComponent(id)}`).then(getData);
}

export function updateContainer({ _id, ...container }) {
	return client.put(`/containers/${encodeURIComponent(_id)}`, container).then(getData);
}

export function createContainer(container) {
	return client.post('/containers', container).then(getData);
}

export function getEvents() {
	return client.get('/events').then(getData);
}

export function getTemplateExportTypes() {
	return client.get('/templates/exporttypes').then(getData);
}

export function getItemTabs() {
	return client.get('/itemtabs').then(getData);
}

export function getItemTab(id) {
	return client.get(`/itemtabs/${encodeURIComponent(id)}`).then(getData);
}

export function updateItemTab({ _id, ...tab }) {
	return client.put(`/itemtabs/${encodeURIComponent(_id)}`, tab);
}

export function createItemTab(tab) {
	return client.post('/itemtabs', tab);
}

export function deleteItemTab(id) {
	return client.delete(`/itemtabs/${encodeURIComponent(id)}`);
}

export function getB2BTabs() {
	return client.get('/b2btabs').then(getData);
}

export function getB2BTab(id) {
	return client.get(`/b2btabs/${encodeURIComponent(id)}`).then(getData);
}

export function updateB2BTab({ _id, ...tab }) {
	return client.put(`/b2btabs/${encodeURIComponent(_id)}`, tab);
}

export function createB2BTab(tab) {
	return client.post('/b2btabs', tab);
}

export function deleteB2BTab(id) {
	return client.delete(`/b2btabs/${encodeURIComponent(id)}`);
}

export function getAITemplates(type, disabled) {
	return client.get(`/${type}/templates`, { params: { active: !disabled } }).then(getData);
}

export function getAITemplate(type, id) {
	return client.get(`/${type}/templates/${encodeURIComponent(id)}`).then(getData);
}

export function saveAITemplate(type, template) {
	return client.post(`/${type}/templates`, template).then(getData);
}

export function updateAITemplate(type, { _id, ...template }) {
	return client.put(`/${type}/templates/${encodeURIComponent(_id)}`, template);
}

export function getAIParameters(type) {
	return client.get(`/${type}/form`).then(getData);
}

export function getAIItemPreview(type, sku, input, maxLength) {
	return client.post(`/${type}/preview`, { sku, input, maxLength }).then(getData);
}

export function generateText(type, item, template, language) {
	return client.post(`/${type}/generate`, { item, template, language }).then(getData);
}
